import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faCheckCircle, faBullseye, faChartLine, faTimes, faPen, faTasks, faFlag } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';


const EntityComponent = ({ type, text, saved, saveEntity, editEntity, removeEntity }) => {
    // Determine the icon based on the type
    let entityIcon;
    if (type === 'goal') {
        entityIcon = faBullseye;
    } else if (type === 'milestone') {
        entityIcon = faFlag;
    } else if (type === 'habit') {
        entityIcon = faChartLine;
    } else if (type === 'todo') {
        entityIcon = faTasks;
    }

    return (
        <Card
            className={`mb-3 shadow-sm ${saved ? 'saved-plan-div' : 'unsaved-plan-div'}`}
        >
            <Card.Body>
                <div className="d-flex align-items-center">
                    <div className="me-3">
                        <FontAwesomeIcon icon={entityIcon}
                            className={`p-1 ${saved ? 'text-success' : 'text-warning'}`}
                        />
                    </div>
                    <div className="w-100">
                        <div className="row d-flex align-items-center py-1">
                            <Card.Title className="text-dark mb-0">
                                <p className="small mb-0">{text}</p>
                            </Card.Title>
                        </div>
                        <div className="row w-100">
                            <small className="col text-muted py-1 d-flex align-items-center py-2">
                                {saved ? (
                                    <>
                                        Saved {type}&nbsp;&nbsp;
                                        <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                                    </>
                                ) : (
                                    <>Draft {type}</>
                                )}
                            </small>
                            <div className="col d-flex justify-content-end align-items-end">
                                <div>
                                    {!saved ? (
                                        <>
                                            <button
                                                className={`dashboard-btn`}
                                                onClick={saveEntity}
                                            >
                                                <FontAwesomeIcon icon={faSave} />
                                            </button>
                                            <button
                                                className={`dashboard-btn`}
                                                onClick={editEntity}
                                            >
                                                <FontAwesomeIcon icon={faPen} />
                                            </button>
                                            <button
                                                className={`dashboard-btn`}
                                                onClick={removeEntity}
                                            >
                                                <FontAwesomeIcon icon={faTimes} />
                                            </button>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Card.Body>
        </Card >
    );
};

export default EntityComponent;
