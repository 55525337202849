import { useContext, useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import Header from "../components/page/Header";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { GoogleLogin } from '@react-oauth/google';
const stripePromise = loadStripe('pk_test_51NfMm2Cwvcp7G6axIcvTtQlKUVL7yw8djLMSu5S9pHCLHS9V3I3vJvYztmXKrLgckrWGcY43yBLB6f7cyX8Jp0vZ00uOAfx5KV');

const Login = () => {
    // State for user credentials and error message
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [errorMessage, setErrorMessage] = useState("");

    // Context and navigation
    const { loading, dispatch } = useContext(AuthContext);
    const navigate = useNavigate();

    // Handle input change
    const handleChange = (e) => {
        setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    };

    // Handle login button click
    const handleStandardLogin = async (e) => {
        console.log("in handleStandardLogin");

        e.preventDefault();
        dispatch({ type: "LOGIN_START" });

        try {
            const res = await api.post(`/api/auth/login`, credentials);

            if (res.status === 200) {
                console.log("res.data.details: " + JSON.stringify(res.data.details));
                const stripeID = res.data.details.stripeCustomerId;

                dispatch({ type: "LOGIN_PASS", payload: res.data.details });
                if (res.data.details.paymentRequired) {
                    console.log("in paymentRequired");

                    // Redirect to Stripe payment URL
                    const response = await api.post(`/api/stripe/createcheckout`, {
                        stripeCustomerId: stripeID,
                        priceId: 'price_1NkSCuCwvcp7G6axFrdJCAMK'
                    });

                    const stripe = await stripePromise;

                    const { error } = await stripe.redirectToCheckout({ sessionId: response.data.id });

                    if (error) {
                        console.error('Error:', error);
                    }
                } else {
                    // Navigate to workspace or another intended route
                    navigate("/workspace");
                }
            }
        } catch (err) {
            if (!err.response) {
                setErrorMessage("No Server Response");
            } else if (err.response.status === 400) {
                setErrorMessage("User not found");
            } else if (err.response.status === 401) {
                setErrorMessage("Incorrect email or password");
            } else {
                setErrorMessage("Login Failed");
            }

            dispatch({ type: "PROCESS_FAIL", payload: err.response.data });
        }
    };

    const handleGoogleAuthResponse = async (response) => {
        console.log("in handleGoogleAuthResponse");
        const tokenId = response.credential;
        console.log("tokenId: " + tokenId);
        console.log("response: " + JSON.stringify(response));
        // Send tokenId to your server for verification
        // Example:
        try {
            console.log("Starting Google login process...");

            // Logging the token sent to the API
            console.log("Token ID sending to backend:", tokenId);

            // API call to log in with Google
            const res = await api.post('/api/auth/login-google', { token: tokenId });

            // Log the response status and data from the server
            console.log("Response from /api/auth/login-google:", res.status, res.data);

            if (res.status === 200) {
                console.log("Login successful, user details:", JSON.stringify(res.data.details));

                const stripeID = res.data.details.stripeCustomerId;
                console.log("Stripe Customer ID:", stripeID);

                // Dispatch successful login action
                dispatch({ type: "LOGIN_PASS", payload: res.data.details });

                if (res.data.details.paymentRequired) {
                    console.log("User requires payment. Initiating payment process...");

                    // Log the price ID and customer ID being sent to Stripe
                    console.log("Creating Stripe checkout session for Customer:", stripeID, "with Price ID: price_1NkSCuCwvcp7G6axFrdJCAMK");

                    // API call to create a Stripe checkout session
                    const response = await api.post(`/api/stripe/createcheckout`, {
                        stripeCustomerId: stripeID,
                        priceId: 'price_1NkSCuCwvcp7G6axFrdJCAMK'
                    });

                    console.log("Checkout session created. Session ID:", response.data.id);

                    const stripe = await stripePromise;

                    // Log before redirecting to Stripe
                    console.log("Redirecting to Stripe checkout...");
                    const { error } = await stripe.redirectToCheckout({ sessionId: response.data.id });

                    if (error) {
                        console.error('Error during Stripe redirect:', error);
                    }
                } else {
                    // Log when no payment is required
                    console.log("No payment required. Navigating to workspace...");
                    navigate("/workspace");
                }
            }
        } catch (err) {
            // Log the error in case of failure
            console.error("Error occurred during Google login process:", err);

            if (!err.response) {
                console.log("No Server Response.");
                setErrorMessage("No Server Response");
            } else if (err.response.status === 400) {
                console.log("User not found (400).");
                setErrorMessage("User not found");
            } else if (err.response.status === 401) {
                console.log("Incorrect email or password (401).");
                setErrorMessage("Incorrect email or password");
            } else {
                console.log("Login Failed with status:", err.response.status);
                setErrorMessage("Login Failed");
            }

            // Log the error response data for further investigation
            if (err.response && err.response.data) {
                console.log("Error response data:", err.response.data);
            }

            dispatch({ type: "PROCESS_FAIL", payload: err.response ? err.response.data : null });
        }

    };

    const handleGoogleFailure = (error) => {
        console.log("in handleGoogleFailure");

        console.error("Google Sign In was unsuccessful. Try Again Later.", error);
        // Handle Google Login failure
        // ...
    };

    return (
        <div className="container-fluid p-0 m-0 vh-100">
            <Helmet>
                <title>Goals & Habits Login</title>
                <meta name="description" content="Login to get started on improving you life" />
            </Helmet>
            <Navbar />
            <Header />
            <div className="container-fluid m-0 p-0 h-75">
                <div className="g-signin2" data-onsuccess="handleGoogleLogin"></div>

                <div className="row d-flex justify-content-center align-items-center p-3 m-0 h-100">
                    <div className="card rounded-3 shadow-sm col-lg-4 col-md-8 col-sm-10 p-0 m-0">
                        <div className="card-header py-2">
                            <h4 className="m-0 fw-normal text-center">Login</h4>
                        </div>
                        {errorMessage && (
                            <>
                                <div className="row p-0 m-0">
                                    <div className="m-0 p-2 text-danger text-center">{errorMessage}</div>
                                    <div className="m-0 p-2 text-success text-center">
                                        <p className="m-0 p-0">
                                            Click <Link to="/resetemail">here</Link> if you need to reset your password.
                                        </p>
                                    </div>
                                </div>
                            </>
                        )}

                        <form className="card-body px-4 m-0">
                            <div className="p-0 my-3 form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    id="email"
                                    type="text"
                                    placeholder="Email"
                                    onChange={handleChange}
                                    className="form-control "
                                />
                            </div>
                            <div className="p-0 my-3 form-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    onChange={handleChange}
                                    className="form-control "
                                />
                            </div>
                            <div className="d-flex justify-content-center p-3">
                                <button
                                    disabled={loading}
                                    onClick={handleStandardLogin}
                                    className="btn secondary-btn w-25"
                                >
                                    <p className="d-flex justify-content-center my-auto">Login</p>
                                </button>
                            </div>
                        </form>
                        {/* Google Login Button */}
                        <div className="mx-auto p-2">
                            <GoogleLogin
                                onSuccess={handleGoogleAuthResponse}
                                onError={() => setErrorMessage("Google Sign In was unsuccessful. Try Again Later.")}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
