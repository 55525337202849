import '../../../src/styles/styles.css';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faChevronDown, faChevronUp, faBullseye, faCheckCircle, faCirclePause, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Card, Collapse, Form } from 'react-bootstrap';

const statusMap = {
    'Not Started': 'Off-Track',
    'Off-Track': 'On-Track',
    'On-Track': 'Complete',
    'Complete': 'Not Started'
};

const getIconForStatus = (status) => {
    const icons = {
        'Not Started': faCirclePause,
        'Off-Track': faChevronDown,
        'On-Track': faChevronUp,
        'Complete': faCheckCircle
    };
    return icons[status] || faCirclePause;
};

const GoalItem = ({ user2goal, index, toggleGoalStatus, updateGoalDetail, updateGoalMeasure, updateGoalDeadline, removeGoal }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [goalDetail, setGoalDetail] = useState(user2goal?.detail || "");
    const [goalMeasure, setGoalMeasure] = useState(user2goal?.measure || "");
    const [goalDeadline, setGoalDeadline] = useState(user2goal?.deadline || "");

    const toggleCollapse = () => setIsCollapsed(!isCollapsed);

    return (
        <Draggable draggableId={user2goal._id.toString()} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`mb-1 shadow-sm dash-card-lg
                    ${user2goal.status === 'Not Started' ? 'not-started-item' : ''}
                    ${user2goal.status === 'Off-Track' ? 'off-track-item' : ''}
                    ${user2goal.status === 'On-Track' ? 'on-track-item' : ''}
                    ${user2goal.status === 'Complete' ? 'complete-item' : ''}`}
                >
                    <Card.Body>
                        <div className="d-flex align-items-center">
                            <div className="me-3">
                                <FontAwesomeIcon icon={faBullseye}
                                    className={`p-1
                                        ${user2goal.status === 'Not Started' ? 'icon-not-started' : ''}
                                        ${user2goal.status === 'Off-Track' ? 'icon-off-track' : ''}
                                        ${user2goal.status === 'On-Track' ? 'icon-on-track' : ''}
                                        ${user2goal.status === 'Complete' ? 'icon-complete' : ''}`}
                                />
                            </div>
                            <div className="flex-grow-1">
                                <div {...provided.dragHandleProps}>
                                    <Card.Title className="text-dark mb-2">
                                        Goal: {user2goal.goal?.headline || "Default Headline"}
                                    </Card.Title>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <small className="text-muted py-1">
                                            <div className='row'>
                                                <div className='col'>
                                                    Status: <strong>{user2goal.status}</strong>
                                                </div>
                                            </div>
                                        </small>
                                        <div className="d-flex align-items-center">
                                            <div
                                                className={`dashboard-btn sq-btn`}
                                                onClick={toggleCollapse}
                                            >
                                                <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />

                                            </div>
                                            <div
                                                className={`dashboard-btn btn-sm`}
                                                onClick={() => removeGoal(user2goal._id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </div>
                                            <div
                                                className={`
                                                    ${user2goal.status === 'Not Started' ? 'dashboard-btn sq-btn' : 'dashboard-btn'}
                                                    ${user2goal.status === 'Off-Track' ? 'dashboard-btn btn-off sq-btn' : 'dashboard-btn'}
                                                    ${user2goal.status === 'On-Track' ? 'dashboard-btn btn-on sq-btn' : 'dashboard-btn'}
                                                    ${user2goal.status === 'Complete' ? 'dashboard-btn btn-complete sq-btn' : 'dashboard-btn'}
                                                    `}
                                                onClick={() => toggleGoalStatus(user2goal._id, statusMap[user2goal.status])}
                                            >
                                                <FontAwesomeIcon icon={getIconForStatus(user2goal.status)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Collapse in={!isCollapsed}>
                            <div id={`collapseGoal${user2goal._id}`} className="mt-3">
                                <Form onSubmit={(e) => e.preventDefault()}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Detail:</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={goalDetail}
                                            onChange={(e) => setGoalDetail(e.target.value)}
                                            onBlur={() => updateGoalDetail(user2goal._id, goalDetail)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Measure:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={goalMeasure}
                                            onChange={(e) => setGoalMeasure(e.target.value)}
                                            onBlur={() => updateGoalMeasure(user2goal._id, goalMeasure)}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Deadline:</Form.Label>
                                        <Form.Control
                                            type="date"
                                            value={goalDeadline}
                                            onChange={(e) => setGoalDeadline(e.target.value)}
                                            onBlur={() => updateGoalDeadline(user2goal._id, goalDeadline)}
                                        />
                                    </Form.Group>
                                </Form>
                                <div className="d-flex flex-wrap">
                                    <DetailSection title="Milestones" items={user2goal?.goal?.milestones} />
                                    <DetailSection title="Habits" items={user2goal?.goal?.habits} />
                                    <DetailSection title="Todos" items={user2goal?.goal?.todos} />
                                </div>
                            </div>
                        </Collapse>
                    </Card.Body>
                </Card>
            )}
        </Draggable>
    );
};

const DetailSection = ({ title, items }) => (
    <div className="col-md-4 col-sm-12 mb-3">
        <Card className="border">
            <Card.Header className="fw-semibold">{title}</Card.Header>
            <Card.Body>
                {items?.length > 0 ? (
                    <ul className="list-unstyled">
                        {items.map(item => <li key={item._id}>{item.text}</li>)}
                    </ul>
                ) : (
                    <p>No {title.toLowerCase()} associated with this goal.</p>
                )}
            </Card.Body>
        </Card>
    </div>
);

export default GoalItem;
