import '../../../src/styles/styles.css';

import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks, faCheckCircle, faFrog, faTrash, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';

const ToDoItem = ({ index, toDo, deleteToDo, toggleToDoFocus, toggleToDoFrog, toggleTodoComplete }) => {
    return (
        <Draggable draggableId={toDo._id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`mb-1 shadow-sm drag-card-lg
                        ${toDo.complete ? 'complete-item' : 'not-started-item'}`}
                >
                    <Card.Body>
                        <div className="row">
                            <div className="col-1 d-flex align-items-center">
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className={`p-1 ${toDo.complete ? 'text-success' : 'text-secondary'}`}
                                />
                            </div>
                            <div className="col-11">
                                <div className="row flex-grow-1">
                                    <div {...provided.dragHandleProps}>
                                        <Card.Title className="text-dark mb-2">
                                            To-Do: {toDo.text || 'Default ToDo Value'}
                                        </Card.Title>
                                        <div className="col-12 flex-grow-1">
                                            <div className="row">
                                                <div className="col-6 text-start">
                                                    <small className="text-muted py-1">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Current Focus: <strong>{toDo.focus ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Difficult Item: <strong>{toDo.frog ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Complete: <strong>{toDo.complete ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                    </small>
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <div className={`dashboard-btn sq-btn text-center`}
                                                        onClick={() => deleteToDo(toDo._id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </div>
                                                    <div className={`dashboard-btn sq-btn text-center me-1 ${toDo.focus ? 'btn-on' : ''}`}
                                                        onClick={() => toggleToDoFocus(toDo._id, !toDo.focus)}
                                                    >
                                                        <FontAwesomeIcon icon={faBullseye} />
                                                    </div>
                                                    <div className={`dashboard-btn sq-btn text-center me-1 ${toDo.frog ? 'btn-on' : ''}`}
                                                        onClick={() => toggleToDoFrog(toDo._id, !toDo.frog)}
                                                    >
                                                        <FontAwesomeIcon icon={faFrog} />
                                                    </div>
                                                    <div className={`dashboard-btn sq-btn text-center ${toDo.complete ? 'btn-complete' : ''}`}
                                                        onClick={() => toggleTodoComplete(toDo._id, !toDo.complete)}
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </Card.Body>
                </Card >
            )}
        </Draggable >
    );
};

export default ToDoItem;
