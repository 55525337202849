import React, { useState, useContext, useEffect, useRef } from "react";
import { api } from "../api/api.js";
import { AuthContext } from "../context/AuthContext";
import { Helmet } from "react-helmet";
import Navbar from "../components/page/Navbar";
import SideBar from "../components/page/SideBar";
import Footer from "../components/page/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';


import EditModal from "../components/page/EditModal";
import EntityComponent from '../components/page/EntityComponent';

const AIPlan = () => {

    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState({
        milestones: false,
        habits: false,
        todos: false,
    });

    const [goal, setGoal] = useState('');
    const [originalGoal, setOriginalGoal] = useState('');
    const [goalEntity, setGoalEntity] = useState([]);
    const [goalSaved, setGoalSaved] = useState(false);

    const [milestones, setMilestones] = useState([]);
    const [habits, setHabits] = useState([]);
    const [todos, setTodos] = useState([]);

    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentEntity, setCurrentEntity] = useState({ type: "", index: -1, value: "" });
    const goalIDRef = useRef("");

    useEffect(() => {
        console.log("UseEffect: Goal Entity Updated:", goalEntity);
        console.log("UseEffect: Milestones Updated:", milestones);
        console.log("UseEffect: Habits Updated:", habits);
        console.log("UseEffect: Todos Updated:", todos);
        console.log("UseEffect: GoalSaved successfully saved - goal, goalID goalSaved:", goal, goalIDRef.current, goalSaved);
    }, [goalEntity, milestones, habits, todos, goalIDRef.current, goalSaved]);

    const handleGoalChange = (e) => {
        setGoal(e.target.value);
        setOriginalGoal(e.target.value);
    };


    const createEntityComponent = (index, type, text, saved, saveEntity, editEntity, removeEntity) => (
        <EntityComponent
            key={index}
            type={type}
            text={text}
            saved={saved}
            saveEntity={() => saveEntity(type.toLowerCase(), text, index)}
            editEntity={() => editEntity(type.toLowerCase(), text, index)}
            removeEntity={() => removeEntity(type.toLowerCase(), index)}
        />
    );

    const handleAdd = async (entityType, entityText) => {
        console.log("AIPlan: handleAdd entityType, entityText, goalID, goalSaved:", entityType, entityText, goalIDRef.current, goalSaved);

        try {
            let url = `/api/${entityType}/createuser`;
            let body = {
                text: entityText,
                description: entityText,
                headline: entityText,
                category: "AIPlan",
                user: user._id,
            };

            if (entityType !== 'goal') {
                console.log("AIPlan: entityType !== goal and goalID", entityType, goalIDRef.current);
                body.goal = goalIDRef.current;
            }
            const response = await api.post(url, body);
            console.log("AIPlan: response.data._id", response.data._id);

            return response.data;
        } catch (err) {
            console.error(`Error adding ${entityType}:`, err);
        }
    };

    const fetchEntities = async (entityType) => {
        console.log("AIPlan: fetchEntities");
        console.log("AIPlan: entityType:", entityType);

        const setLoadingState = (loading) => {
            if (entityType === 'plan') {
                setLoading(prevLoading => ({
                    ...prevLoading,
                    milestones: loading,
                    habits: loading,
                    todos: loading
                }));
            } else {
                setLoading(prevLoading => ({ ...prevLoading, [entityType]: loading }));
            }
        };

        setLoadingState(true);

        try {
            const res = await api.post(`/api/ai/run${entityType}prompt`, { goal });
            const data = res.data.data;

            if (entityType === 'plan') {
                setGoalEntity([createEntityComponent(0, "goal", data.goal, false, saveEntity, handleEditClick, removeEntity)]);
                setMilestones(data.milestones.map((text, index) =>
                    createEntityComponent(index, "milestone", text, false, saveEntity, handleEditClick, removeEntity)
                ));

                const tempArray = data.habits.map((text, index) =>
                    createEntityComponent(index, "habit", text, false, saveEntity, handleEditClick, removeEntity));

                setHabits(tempArray);
                setTodos(data.todos.map((text, index) =>
                    createEntityComponent(index, "todo", text, false, saveEntity, handleEditClick, removeEntity)
                ));
            } else {
                const entityMap = {
                    milestones: setMilestones,
                    habits: setHabits,
                    todos: setTodos,
                };
                entityMap[entityType](data[entityType].map((text, index) =>
                    createEntityComponent(index, entityType.slice(0, -1), text, false, saveEntity, handleEditClick, removeEntity)
                ));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingState(false);
        }
    };

    const editEntity = (entityType, entity, index) => {
        console.log("AIPlan: editEntity");

        switch (entityType) {
            case 'goal':
                setGoalEntity([createEntityComponent(0, "goal", entity, false, saveEntity, handleEditClick, removeEntity)]);
                break;
            case 'milestone':
                setMilestones(
                    milestones.map((milestone, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "milestone", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return milestone;
                    })
                );
                break;
            case 'habit':
                setHabits(
                    habits.map((habit, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "habit", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return habit;
                    })
                );
                break;
            case 'todo':
                setTodos(
                    todos.map((todo, i) => {
                        if (i === index) {
                            return createEntityComponent(i, "todo", entity, false, saveEntity, handleEditClick, removeEntity);
                        }
                        return todo;
                    })
                );
                break;
            default:
                console.error(`Unknown entity type: ${entityType}`);
        }
    };

    const removeEntity = (entityType, index) => {
        console.log("AIPlan: removeEntity");

        switch (entityType.toLowerCase()) {
            case 'goal':
                setGoalEntity([]);
                break;
            case 'milestone':
                updateEntities(milestones, setMilestones);
                break;
            case 'habit':
                updateEntities(habits, setHabits);
                break;
            case 'todo':
                updateEntities(todos, setTodos);
                break;
            default:
                console.error(`Unknown entity type: ${entityType}`);
        }
    };

    const updateEntities = (entities, setEntities, type, index, text) => {
        console.log("AIPlan: updateEntities")

        const updatedEntities = [...entities];
        const tempEntity = createEntityComponent(index, type, text, true, saveEntity, handleEditClick, removeEntity);

        updatedEntities[index] = tempEntity;

        setEntities(updatedEntities);
    };

    const saveEntity = async (entityType, text, index) => {
        console.log("AIPlan: saveEntity - entityType, GoalSaved, goalID, goalEntity:", entityType, goalSaved, goalIDRef.current, goalEntity);

        const tempData = await handleAdd(entityType, text);
        console.log("AIPlan: saveEntity: tempData", tempData);

        if (entityType === 'goal') {
            console.log("AIPlan: saveEntity: type is goal");

            setGoalSaved(true);
            goalIDRef.current = tempData._id;

            setGoalEntity((prevGoalEntity) => {
                const updatedEntity = createEntityComponent(index, "goal", text, true, saveEntity, handleEditClick, removeEntity);
                return [...prevGoalEntity.slice(0, index), updatedEntity, ...prevGoalEntity.slice(index + 1)];
            });
        }
        else {
            console.log("AIPlan: saveEntity: type is", entityType);
            if (entityType === 'milestone') {
                setMilestones((prevMilestones) => {
                    const updatedEntity = createEntityComponent(index, "milestone", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevMilestones.slice(0, index), updatedEntity, ...prevMilestones.slice(index + 1)];
                });
            } else if (entityType === 'habit') {
                setHabits((prevHabits) => {
                    const updatedEntity = createEntityComponent(index, "habit", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevHabits.slice(0, index), updatedEntity, ...prevHabits.slice(index + 1)];
                });
            } else if (entityType === 'todo') {
                setTodos((prevTodos) => {
                    const updatedEntity = createEntityComponent(index, "todo", text, true, saveEntity, handleEditClick, removeEntity);
                    return [...prevTodos.slice(0, index), updatedEntity, ...prevTodos.slice(index + 1)];
                });
            }
        }
    };

    const handleEditClick = (entityType, entity, index) => {
        console.log("AIPlan: handleEditClick");
        console.log("AIPlan: entityType, entity, index:", entityType, entity, index);

        setCurrentEntity({ type: entityType, index, value: entity });
        setEditModalOpen(true);
    };

    const handleEditChange = (e) => {
        console.log("AIPlan: handleEditChange");
        setCurrentEntity({ ...currentEntity, value: e.target.value });
    };

    const handleEditSubmit = () => {
        console.log("AIPlan: handleEditSubmit");
        const { type, index, value } = currentEntity;
        console.log("AIPlan: handleEditSubmit: type, index, value:", type, index, value);
        editEntity(type, value, index);
        setEditModalOpen(false);
    };

    const handleSubmit = async (e) => {
        console.log("AIPlan: handleSubmit");
        e.preventDefault();

        // Use the originalGoal state instead of resetting goal to an empty string
        setGoal(originalGoal);
        setGoalSaved(false);
        goalIDRef.current = "";
        setGoalEntity([]);
        setMilestones([]);
        setHabits([]);
        setTodos([]);

        await fetchEntities('plan');
    };

    console.log('AIPlan: About to Render', goal, milestones, habits, todos, goalSaved, goalIDRef.current);
    return (
        <div className="container-fluid ai-plan-container"> {/* Added custom class */}
            <Helmet>
                <title>Empower Your Life with Goals & Habits Management</title>
                <meta name="description" content="Discover a comprehensive suite of tools designed to propel you towards a more fulfilling life." />
            </Helmet>
            <div className="row bg-light sticky-top"> {/* Changed to bg-light for a softer look */}
                <div className="col border-bottom border-dark">
                    <Navbar />
                </div>
            </div>
            <div className="row flex-nowrap">
                <div className="col-auto px-0">
                    <div id="sidebar" className={`collapse collapse-horizontal ${window.innerWidth < 768 ? '' : 'show'} border-end`}>
                        <SideBar user={user} />
                    </div>
                </div>
                <div className="col flex-grow-1">
                    <div className="row py-3">
                        <div className="col-auto my-auto">
                            <div href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" className="btn border border-dark rounded-3 p-2">
                                Side Menu
                            </div>
                        </div>
                        <div className="col d-flex align-items-center">
                            <h3 className="mb-0">AI Plan</h3> {/* Added text color */}
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-md-4 col-sm-12 mb-3">
                            <div className="shadow d-flex flex-column">
                                <div className="p-2 fw-bold primary-color d-flex justify-content-between align-items-center">
                                    <div className="me-auto d-flex align-items-center">
                                        <h5 className="mb-0">Goal:</h5>
                                    </div>
                                    <button className="btn btn-outline-secondary btn-sm ms-auto d-flex align-items-center"
                                        onClick={() => fetchEntities('plan')}
                                    >
                                        <FontAwesomeIcon icon={faRedo} />
                                    </button>
                                </div>
                                <div className="row" >
                                    <form className="p-4" onSubmit={handleSubmit}>
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="goalInput"
                                                placeholder="Enter your custom goal and click Generate"
                                                value={goal}
                                                onChange={handleGoalChange}
                                            />
                                            <button
                                                type="submit"
                                                className="btn border border-dark"
                                            >
                                                Generate Plan
                                            </button>
                                        </div>
                                    </form>
                                    <div className="px-4 py-1" >
                                        {Array.isArray(goalEntity) ? goalEntity : <p>Error: goalEntity is not an array</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        {/* Milestones Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="shadow d-flex flex-column">
                                <div className="p-2 fw-bold primary-color d-flex justify-content-between align-items-center">
                                    <div className="me-auto d-flex align-items-center">
                                        <h5 className="mb-0">Milestones:</h5>
                                    </div>
                                    <button className="btn btn-outline-secondary btn-sm ms-auto d-flex align-items-center"
                                        onClick={() => fetchEntities('milestones')}
                                    >
                                        <FontAwesomeIcon icon={faRedo} />
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="p-4">
                                        {loading.milestones ? <div className="loading">Loading...</div> : <div>{milestones}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Habits Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="shadow d-flex flex-column">
                                <div className="p-2 fw-bold primary-color d-flex justify-content-between align-items-center">
                                    <div className="me-auto d-flex align-items-center">
                                        <h5 className="mb-0">Habits:</h5>
                                    </div>
                                    <button className="btn btn-outline-secondary btn-sm ms-auto d-flex align-items-center"
                                        onClick={() => fetchEntities('habits')}
                                    >
                                        <FontAwesomeIcon icon={faRedo} />
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="p-4">
                                        {loading.habits ? <div className="loading">Loading...</div> : <div>{habits}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ToDos Card */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="shadow d-flex flex-column">
                                <div className="p-2 fw-bold primary-color d-flex justify-content-between align-items-center">
                                    <div className="me-auto d-flex align-items-center">
                                        <h5 className="mb-0">Todos:</h5>
                                    </div>
                                    <button className="btn btn-outline-secondary btn-sm ms-auto d-flex align-items-center"
                                        onClick={() => fetchEntities('todos')}
                                    >
                                        <FontAwesomeIcon icon={faRedo} />
                                    </button>
                                </div>
                                <div className="row">
                                    <div className="p-4">
                                        {loading.todos ? <div className="loading">Loading...</div> : <div>{todos}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <EditModal
                show={isEditModalOpen}
                onClose={() => setEditModalOpen(false)}
                onSave={handleEditSubmit}
                entity={currentEntity}
                onChange={handleEditChange}
            />
        </div>
    );
};

export default AIPlan;
