import '../../../src/styles/styles.css';
import React, { useState, useEffect } from 'react';


import { Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faCheckCircle, faChartLine, faFrog, faTrash, faBullseye } from "@fortawesome/free-solid-svg-icons";
import { Card, Form, Collapse } from 'react-bootstrap';


const HabitItem = ({ index, user2habit, removeHabit, toggleHabitComplete, toggleHabitFocus,
    toggleHabitFrog, updateHabitStart, updateHabitEnd, updateHabitDetail, habitDay, isEditable }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [habitDetail, setHabitDetail] = useState(user2habit?.detail || "");
    const [habitStart, setHabitStart] = useState(user2habit?.start || "");
    const [habitEnd, setHabitEnd] = useState(user2habit?.end || "");
    const [habitComplete, setHabitComplete] = useState(false);


    const toggleCollapse = () => setIsCollapsed(!isCollapsed);


    useEffect(() => {
        const isHabitComplete = user2habit?.completionHistory.some(history => {
            const historyDate = new Date(history.date).toLocaleDateString();
            const currentDate = habitDay.toLocaleDateString();
            return historyDate === currentDate && history.completed;
        });


        setHabitComplete(isHabitComplete);
    }, [user2habit, habitDay]);


    return (
        <Draggable draggableId={user2habit._id} index={index}>
            {(provided) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    className={`mb-1 shadow-sm dash-card-lg
                        ${habitComplete ? 'complete-item' : 'not-started-item'}`}
                >
                    <Card.Body>
                        <div className="row">
                            <div className="col-1 d-flex align-items-center">
                                <FontAwesomeIcon
                                    icon={faChartLine}
                                    className={`p-1 ${habitComplete ? 'text-success' : 'text-secondary'}`}
                                />
                            </div>
                            <div className="col-11">
                                <div className="row">
                                    <div {...provided.dragHandleProps}>
                                        <div className="col-12 d-flex align-items-center mb-2">
                                            <Card.Title className="text-dark h5">
                                                Habit: {user2habit.habit?.text || "Default Habit Value"}
                                            </Card.Title>
                                        </div>
                                        <div className="col-12 flex-grow-1">
                                            <div className="row">
                                                <div className="col-6 text-start">
                                                    <small className="text-muted py-1">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Current Focus: <strong>{user2habit.focus ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Difficult Item: <strong>{user2habit.frog ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                Complete: <strong>{habitComplete ? 'Yes' : 'No'}</strong>
                                                            </div>
                                                        </div>
                                                    </small>
                                                </div>
                                                <div className="col-6 d-flex justify-content-end">
                                                    <div className="dashboard-btn sq-btn text-center me-1"
                                                        onClick={toggleCollapse}
                                                        aria-expanded={!isCollapsed}
                                                        aria-controls={`collapseHabit${user2habit._id}`}
                                                    >
                                                        <FontAwesomeIcon icon={isCollapsed ? faCaretDown : faCaretUp} />
                                                    </div>
                                                    <div className="dashboard-btn sq-btn text-center me-1"
                                                        onClick={() => removeHabit(user2habit._id)}
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </div>
                                                    <div className={`dashboard-btn sq-btn text-center me-1 ${user2habit.focus ? 'btn-on' : ''}`}
                                                        onClick={() => toggleHabitFocus(user2habit._id, !user2habit.focus)}
                                                    >
                                                        <FontAwesomeIcon icon={faBullseye} />
                                                    </div>

                                                    <div className={`dashboard-btn sq-btn text-center me-1 ${user2habit.frog ? 'btn-on' : ''}`}
                                                        onClick={() => toggleHabitFrog(user2habit._id, !user2habit.frog)}
                                                    >
                                                        <FontAwesomeIcon icon={faFrog} />
                                                    </div>
                                                    <div className={`dashboard-btn sq-btn text-center ${habitComplete ? 'btn-complete' : ''}`}
                                                        onClick={() => toggleHabitComplete(user2habit._id, habitDay, !habitComplete)}
                                                        disabled={!isEditable(habitDay)}
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Collapse in={!isCollapsed}>
                                            <div id={`collapseHabit${user2habit._id}`} className="mt-3">
                                                <Form onSubmit={(e) => e.preventDefault()}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Habit Detail:</Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            value={habitDetail}
                                                            onChange={(e) => setHabitDetail(e.target.value)}
                                                            onBlur={() => updateHabitDetail(user2habit._id, habitDetail)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Daily Start Time:</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={habitStart}
                                                            onChange={(e) => setHabitStart(e.target.value)}
                                                            onBlur={() => updateHabitStart(user2habit._id, habitStart)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Daily End Time:</Form.Label>
                                                        <Form.Control
                                                            type="time"
                                                            value={habitEnd}
                                                            onChange={(e) => setHabitEnd(e.target.value)}
                                                            onBlur={() => updateHabitEnd(user2habit._id, habitEnd)}
                                                        />
                                                    </Form.Group>
                                                </Form>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Draggable>
    );
};


export default HabitItem;